<template>
    <div id="view-header__teleport"></div>
    <div v-if="!notColoredHeader.routes.includes($route.name)">
      <div
        :class="{
          'view-header--protocol-details' : !notColoredHeader.routes.includes($route.name)
        }"
        class="view-header">
        <div class="view-header__header">
          <div class="tmp-col">
            <Btn
              v-if="helperService.userHasPermission('essaievenement_oremove')"
              text="Supprimer la tâche expérimentale"
              :hollow="!notColoredHeader.routes.includes($route.name)"
              :color="!notColoredHeader.routes.includes($route.name) ? 'white': 'primary'"
              icon="trash-alt"
              @click="modal.deleteEvent = true"
            />
          </div>
        </div>
        <div class="view-header__footer">
          <Btn
            v-if="protocol.id"
            class="back-btn"
            round
            grow
            icon="arrow-left"
            :to="{
              name: 'protocolRatingCalendarEdit',
              params: {
                id: protocol.id,
              },
            }"
          />
          <h1 class="page-title">
            <template v-if="!notColoredHeader.routes.includes($route.name)">
              <template v-if="!task?.designation">
                {{ task?.type?.designation }}
              </template>
              <template v-else>
                {{ task?.type?.designation }} - {{ task?.designation }}
              </template>
            </template>
            <template v-else>
              <template v-if="!task?.designation">
                {{ task?.type?.designation }} - {{ pageTitle }}
              </template>
              <template v-else>
                {{ task?.type?.designation }} - {{ task?.designation }} - {{ pageTitle }}
              </template>
            </template>
            <div class="page-subtitle">Protocole : {{ protocol.titre }}</div>
          </h1>
        </div>
      </div>
    </div>

  <div class="view-body">
    <Section class="section section--no-padding">
      <TabsMaterial
        v-if="!notColoredHeader.routes.includes($route.name)"
        :tabs="tabs"
      />

      <router-view
        :key="componentKey"
        v-if="Object.keys(task).length"
        :task="task"
        :pageTitle="pageTitle"
        @update-task="setTask($event)"
      >
      </router-view>
    </Section>
  </div>

  <NavigationDropdownProtocol />

  <!-- Modals modalDelete-->
  <Modal
    title="Supprimer la tâche expérimentale&nbsp;?"
    :active="modal.deleteEvent"
    :data="modalData"
    @modal-close="modal.deleteEvent = false"
  >
    <template v-slot:modal-body>
      <p>
        Voulez vous vraiment supprimer la tâche expérimentale ?
      </p>
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal.deleteEvent = false" />
      <Btn
        text="Supprimer"
        @click="deleteEvent()"
        icon="trash-alt"
        color="primary"
      />
    </template>
  </Modal>
</template>

<script>
import Section from '@/components/layout/Section.vue'
import Btn from '@/components/base/Btn.vue'
import TabsMaterial from '@/components/layout/TabsMaterial.vue'
import NavigationDropdownProtocol from '@/views/componentsViews/navigationDropdown/NavigationDropdownProtocol.vue'
import Modal from '@/components/layout/Modal.vue'

export default {
  name: 'ExperimentalTaskAddEditView',

  components: {
    Modal,
    NavigationDropdownProtocol,
    Btn,
    Section,
    TabsMaterial,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      notColoredHeader: {
        routes: [
          'protocolExperimentalTaskVariableStudiedSettingsAdd',
          'protocolExperimentalTaskVariableStudiedEntryLevelAdd',
          'protocolExperimentalTaskVariableStudiedSettingsEdit',
          'protocolExperimentalTaskVariableStudiedEntryLevelEdit',
        ],
      },
      task: {},
      notation: {},
      protocol: {},
      modal: {
        deleteEvent: false,
        realizeEvent: false,
      },
      modalData: {},
      realizationDate: new Date().toISOString().split('T')[0],
      componentKey: 0,
    }
  },

  computed: {
    tabs() {
      return [
        {
          urlName: 'protocolExperimentalTaskGeneralInformationEdit', text: 'Informations générales',
        },
        ...(this.task?.type?.uid === 'NOTATION'
          ? [
            { urlName: 'protocolExperimentalTaskVariableStudiedListEdit', text: 'Variables étudiées' },
            { urlName: 'protocolExperimentalTaskVariableCalculatedEdit', text: 'Variables calculées' },
          ]
          : []
        ), // Notation
      ]
    },
  },

  mounted() {
    this.getProtocole()
    this.getExperimentalTask()
  },

  methods: {
    setTask(e) {
      this.task = e
    },
    openModal(modal, data) {
      this.modalData = data
      this.modal[modal] = true
    },

    getProtocole() {
      this.fetchService.get(`protocole/${this.$route.params.id}`).then(
        (response) => {
          this.protocol = response.data
        },
      )
    },

    getExperimentalTask() {
      this.fetchService.get(`protocole/${this.$route.params.id}/evenement/${this.$route.params.tid}`)
        .then((response) => {
          this.task = response.data

          this.$nextTick(() => {
            this.componentKey += 1
          })
        })
    },

    deleteEvent() {
      this.emitter.emit('open-loader')

      this.fetchService
        .delete(`protocole/${this.$route.params.id}/evenement/${this.$route.params.tid}`)
        .then(
          () => {
            this.emitter.emit('alert', {
              type: 'success',
              content: 'La tâche expérimentale a bien été supprimé.',
            })

            this.$router.push({
              name: 'protocolRatingCalendarEdit',
              params: {
                id: this.$route.params.id,
              },
            })
          },
          (responseError) => {
            this.emitter.emit('alert', {
              type: 'error',
              content: responseError.data,
            })
          },
        )

      this.emitter.emit('close-loader')
      this.modal.deleteEvent = false
    },
  },
}
</script>

<style lang="scss" scoped>
.view-header__header {
  display: flex;
  gap: $gutter-half;
}
</style>
